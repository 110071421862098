import BooksOrAboutPage from './BooksOrAboutPage';
import Footer from './Footer';

import './App.css';

const App = () => {
  return (
    <div className="App">
      <>
        <h1 className="header">
          Marginal Revolution Books 2.0
        </h1>

      </>
      <BooksOrAboutPage />
      <Footer />
    </div>
  );
}

export default App;
