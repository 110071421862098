

interface Props {
  setShowAboutPage: Function
}

const About = (props: Props) => {
  return (
    <div>
      <p className="subheader">
        Every amazon link ever posted to&nbsp;
        <a className='subheader-link' href="https://marginalrevolution.com/">
          Marginal Revolution
        </a>
        &nbsp;
        <span className='subheader-nav' onClick={() => props.setShowAboutPage(false)}>
          Go back to the books!
        </span>
      </p>
      <h1>About this website</h1>
      <p>Marginal Revolution Books 2.0 is a simple index of every amazon link ever posted
        to <a href="https://marginalrevolution.com/">Marginal Revolution</a>. I built it
        for people like me, fans of the blog who read a lot of the books that Alex and Tyler
        post about.
      </p>
      <p>The index automatically checks MR for new books a few times a day.</p>
      <p>What's next? I'm working on filtering and search features. Feel free to contact
        me on <a href='https://fosstodon.org/@linuxfan2718'>Mastodon</a>.
      </p>
      <p className='subheader-nav' onClick={() => props.setShowAboutPage(false)}>
        Click here to go back to the books!
      </p>
    </div>
  );
};

export default About;