import Books from "./Books"
import About from "./About"
import { useState } from "react"

const BooksOrAboutPage = () => {
  const [showAboutPage, setShowAboutPage] = useState(false)
  return (
    <>
      { showAboutPage ? 
      <About setShowAboutPage={setShowAboutPage} /> : 
      <Books setShowAboutPage={setShowAboutPage} /> }
    </>
    
  )
}

export default BooksOrAboutPage