import { useEffect, useState } from "react"
import Book from "./Book";
import uri from "./uri";

const Books = (props) => {
  const useR2 = true;
  const databaseName = "mrbooksdb";
  const databaseVersion = 1;
  const [networkAnchors, setNetworkAnchors] = useState([]);
  const [displayAnchors, setDisplayAnchors] = useState([]);
  const [numBooks, setNumBooks] = useState(50);
  const [searching, setSearching] = useState(false);

  // on page load, retrieve anchors async from KV store
  // op is done when networkAnchors is not empty
  useEffect(() => {
    const getNetworkAnchors = async () => {
      let skipRefresh = false;
      // grab highest id from Cloudflare KV store
      const highestIdDataUrl = `${uri('highest_id')}`;
      const highestIdDataResponse = await fetch(highestIdDataUrl);
      const highestIdStringData = await highestIdDataResponse.text();
      const highestIdInt = parseInt(highestIdStringData);
      // look up highest id from local Indexed DB
      const request = window.indexedDB.open(databaseName, databaseVersion);
      request.onerror = (event) => {
        console.error("Error opening database. error code", request.errorCode);
        console.error("event", event);
      };
      request.onsuccess = (event) => {
        let db = event.target.result;
        let keysPromise = db.transaction('anchors').objectStore('anchors').getAllKeys();

        keysPromise.onerror = (event) => {
          console.error("NA. Error getting anchors from database. error code", keysPromise.errorCode);
          console.error("NA. anchorsPromise event", event);
        };

        keysPromise.onsuccess = (event) => {
          let res = event.target.result;
          let maxId = Math.max(...res);
          skipRefresh = maxId === highestIdInt;
          if (skipRefresh) {
            console.log("Local books cache up-to-date. Skipping refresh.");
          } else {
            console.log("Local books cache out-of-date. Refreshing.");
            let keyParam = 'anchors';
            if (useR2) {
              console.log('using Brotli endpoint');
              keyParam = 'anchorsr2';
            }
            const networkAnchorsDataUrl = `${uri(keyParam)}`;
            fetch(networkAnchorsDataUrl)
              .then((response) => response.json())
              .then((data) => setNetworkAnchors(data))
              .catch((error) => {
                console.error('Retrieve KV anchors Error:', error);
              });
            
          }
        };
      }
    };
    getNetworkAnchors();
  }, [useR2])

  // on page load, set up db if needed
  // render page using anchors from db (even if empty)
  useEffect(() => {
    const createDatabaseAndSetDisplayAnchors = () => {
      // create database
      const request = window.indexedDB.open(databaseName, databaseVersion);

      request.onerror = (event) => {
        console.error("Error opening database. error code", request.errorCode);
        console.error("event", event);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        // migration: create anchors table
        const objectStore = db.createObjectStore("anchors", { keyPath: "id" });
        objectStore.createIndex("author", "article.author", { unique: false });
        objectStore.createIndex("published_at", "article.published_at", { unique: false });
      };

      // set displayAnchors for first render
      request.onsuccess = (event) => {
        let db = event.target.result;
        let anchorsPromise = db.transaction('anchors').objectStore('anchors').getAll();

        anchorsPromise.onerror = (event) => {
          console.error("Error getting anchors from database. error code", anchorsPromise.errorCode);
          console.error("anchorsPromise event", event);
        };

        anchorsPromise.onsuccess = (event) => {
          let dbAnchors = event.target.result.slice().reverse();
          setDisplayAnchors(dbAnchors);
        };
      }
    };
    createDatabaseAndSetDisplayAnchors();
  }, [])

  // whenever networkAnchors changes, update db
  useEffect(() => {
    const updateDatabase = () => {
      const request = window.indexedDB.open(databaseName, databaseVersion);

      request.onerror = (event) => {
        console.error("Error opening database. error code", request.errorCode);
        console.error("updateDatabase event", event);
      };

      request.onsuccess = (event) => {
        console.log("Local Indexed DB opened successfully.")
        let db = event.target.result;
        let transaction = db.transaction('anchors', 'readwrite');
        let objectStore = transaction.objectStore('anchors');
        networkAnchors.forEach((anchor) => {
          objectStore.put(anchor);
        });
        // refresh displayAnchors
        let anchorsPromise = objectStore.getAll();
        anchorsPromise.onerror = (event) => {
          console.error("updateDatabase: Error getting anchors from database. error code")
          console.error(anchorsPromise.errorCode);
          console.error("updateDatabase: anchorsPromise event", event);
        };
  
        anchorsPromise.onsuccess = (event) => {
          let dbAnchors = event.target.result.slice().reverse();
          setDisplayAnchors(dbAnchors);
        };
      }
    };
    updateDatabase();
  }, [networkAnchors])

  const newestFirstAnchors = () => {
    setSearching(false);
    const request = window.indexedDB.open(databaseName, databaseVersion);
    request.onsuccess = (event) => {
      let db = event.target.result;
      let anchorsPromise = db.transaction('anchors').objectStore('anchors').getAll();

      anchorsPromise.onerror = (event) => {
        console.error("Error getting anchors from database. error code", anchorsPromise.errorCode);
        console.error("anchorsPromise event", event);
      };

      anchorsPromise.onsuccess = (event) => {
        let dbAnchors = event.target.result.slice().reverse();
        setDisplayAnchors(dbAnchors);
      };
    }
  }

  const reverseAnchors = () => {
    setDisplayAnchors(displayAnchors.slice().reverse());
  };
  const randomizeAnchors = () => {
    const shuffledAnchors = shuffle(displayAnchors.slice());
    setDisplayAnchors(shuffledAnchors);
  };

  // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array#2450976
  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  const searchAnchors = () => {
    setSearching(true);
    const request = window.indexedDB.open(databaseName, databaseVersion);
    request.onsuccess = (event) => {
      let db = event.target.result;
      let anchorsPromise = db.transaction('anchors').objectStore('anchors').getAll();

      anchorsPromise.onerror = (event) => {
        console.error("Error getting anchors from database. error code", anchorsPromise.errorCode);
        console.error("anchorsPromise event", event);
      };

      anchorsPromise.onsuccess = (event) => {
        const anchors = event.target.result.slice();
        const searchTerm = document.getElementById('searchTerm').value;
        console.log(searchTerm);
        const filteredAnchors = anchors.filter(
          anchor => anchor['article']['article_text'].includes(searchTerm)
        );
        console.log(filteredAnchors.length);
        setDisplayAnchors(filteredAnchors);
      };
    }
  };

  return(
    <>
      <p className="subheader">
        Every amazon link ever posted to&nbsp;
        <a className='subheader-link' href="https://marginalrevolution.com/">
          Marginal Revolution
        </a>
        &nbsp;
        <span className='subheader-nav' onClick={() => props.setShowAboutPage(true)}>
          What is this?
        </span>
      </p>
      <button onClick={() => {newestFirstAnchors()}}>Reset books</button>
      &nbsp;
      <button onClick={() => {reverseAnchors()}}>Reverse order</button>
      &nbsp;
      <button onClick={() => {randomizeAnchors()}}>Randomize order</button>
      &nbsp;
      <input 
        type="text"
        id="searchTerm"
        name="searchTerm"
        onKeyPress={(e) => {e['key'] === 'Enter' && searchAnchors();}}>
      </input>
      &nbsp;
      <button onClick={() => {searchAnchors()}}>Search</button>
      <p>
        {displayAnchors.length} Books found.
      </p>
      { displayAnchors.slice(0,numBooks).map( anchor => 
          <Book 
            anchor={anchor}
            key={anchor["id"]}
            searching={searching}
            searchTerm={document.getElementById('searchTerm').value}
          />
      )}
      <br />
      { displayAnchors.length === 0 && <p>No books found.</p> }
      { (displayAnchors.length > numBooks) && <button onClick={() => {setNumBooks(numBooks + 50);}}>MORE BOOKS</button> }
      { (displayAnchors.length <= numBooks) && <p>No more books!</p> }
    </>
  )
}

export default Books
