interface Props {
  anchor: Anchor
  searching: Boolean
  searchTerm: string
}

interface Anchor {
  id: string,
  text: string,
  img_src: string,
  href: string,
  affiliate_href: string,
  article: {
    href: string,
    title: string,
    author: string,
    published_at: string,
    paragraphs: [{
      text: string,
      blockquote: boolean,
      id: string
    }]
  }
}

const Book = (props: Props) => {
  const styleText = (linkText: string, entireText:string, id: string, href: string) => {
    let splitText = entireText.split(linkText)
    return <div key={id}>{splitText[0]}<a href={href} className='highlight'>{linkText}</a>{splitText[1]}</div>
  }
  const styleSearchResult = (entireText:string, id: string, href: string) => {
    let splitText = entireText.split(props.searchTerm)
    if (splitText.length === 1) {
      return  <div key={id}>
                <div>
                  {splitText[0]}
                </div>
                <p></p>
              </div>
    } else {
      return  <div key={id}>
                <div>
                  {splitText[0]}
                  <a href={href} className='highlight'>
                    {props.searchTerm}
                  </a>
                  {splitText[1]}
                </div>
                <p></p>
              </div>
    }
  }
  const anchor = props.anchor;
  return (
    <div className="book">
      <div className="book-image">
        <a href={anchor["affiliate_href"]}>
          <img alt={anchor["text"]} src={anchor["img_src"]} />
        </a>
      </div>
      <div className="book-description">
        <p className='book-title'><a href={anchor["article"]["href"]}>{anchor["article"]["title"]}</a></p>
        by <strong>{anchor["article"]["author"]}</strong>{' '}
        on {new Intl.DateTimeFormat('en-US').format(new Date(anchor["article"]["published_at"]))}
        <p></p>
        <div>
          {
            props.searching ?
              anchor["article"]["paragraphs"]
                .map ((p) => {
                  return styleSearchResult(p["text"], p["id"], anchor["affiliate_href"])
                }) :
              anchor["article"]["paragraphs"]
                .filter(function(p) { return p["text"].includes(anchor["text"]) } )
                .map ((p) => {
                  return styleText(anchor["text"], p["text"], p["id"], anchor["affiliate_href"])
                })
          }
        </div>
      </div>
    </div>
  )
}

export default Book;